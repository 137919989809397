import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    FormControlLabel,
    Checkbox,
    Stack,
    TextField,
    Button,
    Switch,
    MenuItem,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from 'luxon';

import { useUser } from "./user-provider";
import BusinessSetupStepper from "./business-setup-stepper";
import { getSupportedDataImportTypes } from '../../common/src/supported-data-import-types'; // Adjust the import path as needed

const supportedDataImportTypes = getSupportedDataImportTypes();

// New FileIntegrationConfig component
const FileIntegrationConfig: React.FC<{
    config: any;
    setConfig: React.Dispatch<React.SetStateAction<any>>;
    title: string;
    showDataTypeSelection?: boolean;
    integrationKey: string;
    integrations: any;
    handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    thirdPartyButtons?: JSX.Element;
}> = ({
    config,
    setConfig,
    title,
    showDataTypeSelection = false,
    integrationKey,
    integrations,
    handleCheckboxChange,
    thirdPartyButtons,
}) => {
    const handleConfigChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setConfig({
            ...config,
            [event.target.name]: event.target.value,
        });
    };

    const handleDataTypeChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setConfig({
            ...config,
            dataType: event.target.value,
        });
    };

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <Switch
                    checked={integrations[integrationKey]}
                    onChange={handleCheckboxChange}
                    name={integrationKey}
                />
            </Box>
            {integrations[integrationKey] && (
                <Box sx={{ mt: 2 }}>
                    <TextField
                        label="File Path"
                        placeholder="/path/to/file"
                        fullWidth
                        margin="normal"
                        name="filePath"
                        value={config.filePath}
                        onChange={handleConfigChange}
                    />
                    <TextField
                        label="File Name Pattern"
                        placeholder="data_*.csv"
                        fullWidth
                        margin="normal"
                        name="filePattern"
                        value={config.filePattern}
                        onChange={handleConfigChange}
                    />
                    {thirdPartyButtons && (
                        <>
                            <Typography variant="body2">
                                Or connect to a third-party platform:
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                {thirdPartyButtons}
                            </Box>
                        </>
                    )}
                    <TextField
                        select
                        label="Polling Frequency"
                        name="pollingFrequency"
                        value={config.pollingFrequency}
                        onChange={handleConfigChange}
                        fullWidth
                        margin="normal"
                        SelectProps={{ native: true }}
                    >
                        <option value="Hourly">Hourly</option>
                        <option value="Daily">Daily</option>
                        <option value="Weekly">Weekly</option>
                    </TextField>
                    {config.pollingFrequency === "Daily" && (
                        <TimePicker
                            label="Polling Time"
                            value={config.pollingTime}
                            onChange={(newValue: DateTime | null) => {
                                if (newValue) {
                                    setConfig({
                                        ...config,
                                        pollingTime: newValue,
                                    });
                                }
                            }}
                            slots={{ textField: TextField }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    margin: "normal",
                                },
                            }}
                            ampm={false}
                            // minTime={new Date().setHours(0, 0, 0, 0)} // Optional: Set minimum time if needed
                            disableOpenPicker={false} // Ensure picker is enabled
                        />
                    )}
                    {showDataTypeSelection && (
                        <TextField
                            select
                            label="Data Type"
                            name="dataType"
                            value={config.dataType}
                            onChange={handleDataTypeChange}
                            fullWidth
                            margin="normal"
                        >
                            {supportedDataImportTypes.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                    <TextField
                        label="SFTP Host"
                        name="sftpHost"
                        value={config.sftpHost}
                        onChange={handleConfigChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="SFTP Port"
                        name="sftpPort"
                        value={config.sftpPort}
                        onChange={handleConfigChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="SFTP Username"
                        name="sftpUsername"
                        value={config.sftpUsername}
                        onChange={handleConfigChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="SFTP Password"
                        type="password"
                        name="sftpPassword"
                        value={config.sftpPassword}
                        onChange={handleConfigChange}
                        fullWidth
                        margin="normal"
                    />
                </Box>
            )}
        </>
    );
};

const BusinessIntegrations: React.FC = () => {
    const { setBusinessSetupCompletedStep } = useUser();

    const supportedDataImportTypes = getSupportedDataImportTypes();

    // Define default polling time as a Luxon DateTime object set to 03:00 AM
    const defaultPollingTime = DateTime.local().set({ hour: 3, minute: 0, second: 0, millisecond: 0 });

    const [integrations, setIntegrations] = useState({
        googleTrends: false,
        tiktok: false,
        googleAds: false,
        stockControl: false,
        googleAnalytics: false,
        customAPI: false,
        salesData: false, // Add salesData integration
        customDataFile: false, // New integration
    });

    const [customAPIConfig, setCustomAPIConfig] = useState({
        url: "",
        apiKey: "",
        dataType: "", // Added dataType
    });

    const [customDataFileConfig, setCustomDataFileConfig] = useState({
        filePath: "",
        filePattern: "",
        dataType: "", // Added dataType
        sftpHost: "",
        sftpPort: "",
        sftpUsername: "",
        sftpPassword: "",
        pollingFrequency: "Daily",
        pollingTime: defaultPollingTime,
    });

    const [googleTrendsConfig, setGoogleTrendsConfig] = useState({});
    const [tiktokConfig, setTikTokConfig] = useState({});
    const [googleAdsConfig, setGoogleAdsConfig] = useState({});
    const [stockControlConfig, setStockControlConfig] = useState({
        pollingFrequency: "Daily",
        pollingTime: defaultPollingTime,
        sftpHost: "",
        sftpPort: "",
        sftpUsername: "",
        sftpPassword: "",
    });
    const [googleAnalyticsConfig, setGoogleAnalyticsConfig] = useState({});
    const [salesDataConfig, setSalesDataConfig] = useState({
        pollingFrequency: "Daily",
        pollingTime: defaultPollingTime,
        sftpHost: "",
        sftpPort: "",
        sftpUsername: "",
        sftpPassword: "",
    });

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setIntegrations({
            ...integrations,
            [event.target.name]: event.target.checked,
        });
    };

    const handleConfigChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomAPIConfig({
            ...customAPIConfig,
            [event.target.name]: event.target.value,
        });
    };

    const handleSalesDataConfigChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setSalesDataConfig({
            ...salesDataConfig,
            [event.target.name]: event.target.value,
        });
    };

    const handleStockControlConfigChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setStockControlConfig({
            ...stockControlConfig,
            [event.target.name]: event.target.value,
        });
    };

    const handleCustomDataFileConfigChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setCustomDataFileConfig({
            ...customDataFileConfig,
            [event.target.name]: event.target.value,
        });
    };

    const handleCustomAPIDataTypeChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setCustomAPIConfig({
            ...customAPIConfig,
            dataType: event.target.value,
        });
    };

    const handleCustomDataFileDataTypeChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setCustomDataFileConfig({
            ...customDataFileConfig,
            dataType: event.target.value,
        });
    };

    const handleCallAnalyse = () => {
        // Dummy function to simulate API call and data schema analysis
        console.log(
            "Calling API with URL:",
            customAPIConfig.url,
            "and API Key:",
            customAPIConfig.apiKey,
        );
        // Implement actual API call and analysis logic here
    };

    useEffect(() => {
        const isFinished =
            integrations.googleTrends ||
            integrations.tiktok ||
            integrations.googleAds ||
            integrations.stockControl ||
            integrations.googleAnalytics ||
            integrations.customAPI ||
            integrations.salesData;

        setBusinessSetupCompletedStep(1, isFinished);
    }, [integrations, setBusinessSetupCompletedStep]);

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Box sx={{ p: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Business Integrations
                </Typography>

                <BusinessSetupStepper activeStep={1} />

                <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                    Connect Your Marketing Data Sources
                </Typography>

                <Typography variant="body1" paragraph>
                    Integrations provide MudlarkGTM with continuous access to
                    your marketing data sources. This allows our AI agents to
                    incorporate the latest data from your connected platforms
                    when analyzing your business position and making
                    recommendations.
                </Typography>

                <Typography variant="body1" paragraph>
                    By connecting data sources like Google Analytics, TikTok, or
                    your stock control system, you enable our AI agents to:
                    <ul>
                        <li>
                            Monitor trends and patterns in your marketing
                            performance
                        </li>
                        <li>
                            Identify opportunities and potential issues in
                            real-time
                        </li>
                        <li>
                            Make data-driven recommendations tailored to your
                            business
                        </li>
                        <li>
                            Track the effectiveness of implemented marketing
                            strategies
                        </li>
                    </ul>
                </Typography>

                <Stack direction="column" spacing={2}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography variant="h6" gutterBottom>
                            Integrate with Google Trends
                        </Typography>
                        <Switch
                            checked={integrations.googleTrends}
                            onChange={handleCheckboxChange}
                            name="googleTrends"
                        />
                    </Box>
                    {integrations.googleTrends && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1">
                                The Google Trends integration doesn't require any additional configuration and is ready to use.
                            </Typography>
                        </Box>
                    )}
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography variant="h6" gutterBottom>
                            Integrate with TikTok
                        </Typography>
                        <Switch
                            checked={integrations.tiktok}
                            onChange={handleCheckboxChange}
                            name="tiktok"
                        />
                    </Box>
                    {integrations.tiktok && (
                        <Box sx={{ mt: 2 }}>
                            <Button variant="contained">
                                Connect to TikTok
                            </Button>
                        </Box>
                    )}
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography variant="h6" gutterBottom>
                            Integrate with your Google Ads Console
                        </Typography>
                        <Switch
                            checked={integrations.googleAds}
                            onChange={handleCheckboxChange}
                            name="googleAds"
                        />
                    </Box>
                    {integrations.googleAds && (
                        <Box sx={{ mt: 2 }}>
                            <Button variant="contained">
                                Connect to Google Ads
                            </Button>
                        </Box>
                    )}
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography variant="h6" gutterBottom>
                            Google Analytics Integration
                        </Typography>
                        <Switch
                            checked={integrations.googleAnalytics}
                            onChange={handleCheckboxChange}
                            name="googleAnalytics"
                        />
                    </Box>
                    {integrations.googleAnalytics && (
                        <Box sx={{ mt: 2 }}>
                            <Button variant="contained">
                                Connect to Google Analytics
                            </Button>
                        </Box>
                    )}

                    <FileIntegrationConfig
                        title="Sales Data Integration"
                        config={salesDataConfig}
                        setConfig={setSalesDataConfig}
                        showDataTypeSelection={false}
                        integrationKey="salesData"
                        integrations={integrations}
                        handleCheckboxChange={handleCheckboxChange}
                        thirdPartyButtons={
                            <Stack
                                direction="column"
                                spacing={2}
                                sx={{ mt: 2, mb: 2 }}
                            >
                                <Button variant="contained">
                                    Connect to Salesforce
                                </Button>
                                <Button variant="contained">
                                    Connect to Microsoft Dynamics
                                </Button>
                            </Stack>
                        }
                    />

                    <FileIntegrationConfig
                        title="Stock Control Integration"
                        config={stockControlConfig}
                        setConfig={setStockControlConfig}
                        showDataTypeSelection={false}
                        integrationKey="stockControl"
                        integrations={integrations}
                        handleCheckboxChange={handleCheckboxChange}
                        thirdPartyButtons={
                            <Stack
                                direction="column"
                                spacing={2}
                                sx={{ mt: 2, mb: 2 }}
                            >
                                <Button variant="contained">
                                    Connect to SAP
                                </Button>
                                <Button variant="contained">
                                    Connect to Oracle SCM
                                </Button>
                            </Stack>
                        }
                    />

                    <FileIntegrationConfig
                        title="Custom Data File Integration"
                        config={customDataFileConfig}
                        setConfig={setCustomDataFileConfig}
                        showDataTypeSelection={true}
                        integrationKey="customDataFile"
                        integrations={integrations}
                        handleCheckboxChange={handleCheckboxChange}
                    />

                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography variant="h6" gutterBottom>
                            Custom API Integration
                        </Typography>
                        <Switch
                            checked={integrations.customAPI}
                            onChange={handleCheckboxChange}
                            name="customAPI"
                        />
                    </Box>
                    {integrations.customAPI && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Custom API Configuration
                            </Typography>
                            <TextField
                                label="API URL"
                                name="url"
                                value={customAPIConfig.url}
                                onChange={handleConfigChange}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="API Key"
                                name="apiKey"
                                value={customAPIConfig.apiKey}
                                onChange={handleConfigChange}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                select
                                label="Data Type"
                                name="customAPIDataType"
                                value={customAPIConfig.dataType}
                                onChange={handleCustomAPIDataTypeChange}
                                fullWidth
                                margin="normal"
                            >
                                {supportedDataImportTypes.map((type) => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCallAnalyse}
                                sx={{ mt: 1 }}
                            >
                                Call and Analyse Data Schema
                            </Button>
                        </Box>
                    )}
                </Stack>
            </Box>
        </LocalizationProvider>
    );
};

export default BusinessIntegrations;
