import React from "react";
import { Box, Typography } from "@mui/material";
import { useUser } from "./user-provider";
import BusinessSetupStepper from "./business-setup-stepper";

export const Home: React.FC = () => {
    const { userDisplayName, businessDisplayName } = useUser();

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Welcome, {userDisplayName} from {businessDisplayName}
            </Typography>

            <Box sx={{ mb: 4 }}>
                <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
                    Getting Started with MudlarkGTM
                </Typography>

                <Typography variant="body1" paragraph>
                    We'll guide you through a simple setup process to help our
                    AI agents understand your business and provide you with
                    valuable marketing recommendations.
                </Typography>

                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                    The Setup Process
                </Typography>

                <Box component="ol" sx={{ mt: 2 }}>
                    <Box component="li" sx={{ mb: 1 }}>
                        <Typography variant="body1">
                            <strong>Business Information:</strong> Tell us about
                            your business, its goals, and target market.
                        </Typography>
                    </Box>
                    <Box component="li" sx={{ mb: 1 }}>
                        <Typography variant="body1">
                            <strong>Business Integrations:</strong> Connect with
                            platforms like Google Analytics, TikTok, and other
                            data sources to help us understand your current
                            marketing performance.
                        </Typography>
                    </Box>
                    <Box component="li" sx={{ mb: 1 }}>
                        <Typography variant="body1">
                            <strong>Data Import:</strong> Share additional
                            business data through spreadsheets to give our AI
                            agents more context about your operations and
                            performance.
                        </Typography>
                    </Box>
                    <Box component="li" sx={{ mb: 1 }}>
                        <Typography variant="body1">
                            <strong>Project Creation:</strong> Set up your first
                            marketing project and start receiving AI-powered
                            recommendations.
                        </Typography>
                    </Box>
                </Box>

                <Typography variant="body1" sx={{ mt: 3 }}>
                    Use the steps below to navigate through the setup process.
                    You can return to any step at any time to update your
                    information.
                </Typography>
            </Box>

            <BusinessSetupStepper />
        </Box>
    );
};

export default Home;
