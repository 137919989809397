import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    ReactNode,
    useCallback,
    useMemo,
} from "react";

import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { getAPIClient } from "./client-id";
import { useSnackbar } from "./snackbar-context";
import { CircularProgress } from "@mui/material";
import { Business, User } from "@mudlark/common";


interface UserContextProps {
    // read only
    userName: string;
    businessName: string;

    // read write
    userDisplayName: string;
    businessDisplayName: string;
    businessInfoChatId: string | null;
    businessSetupCompletedSteps: Set<number>;

    setUserDisplayName: (userDisplayName: string) => Promise<void>;
    setBusinessDisplayName: (businessDisplayName: string) => Promise<void>;
    setBusinessInfoChatId: (businessInfoChatId: string) => Promise<void>;
    setBusinessSetupCompletedSteps: (completedSteps: Set<number>) => Promise<void>;
    setBusinessSetupCompletedStep: (step: number, completed: boolean) => Promise<void>;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

function areSetsEqual<T>(set1: Set<T>, set2: Set<T>): boolean {
    if (set1.size !== set2.size) {
        console.log("BSBSBSBS Sets are not equal: different sizes:", [...set1], [...set2]);
        return false;
    }

    const result = Array.from(set1).every((value) => set2.has(value));

    if (result) {
        console.log("BSBSBSBS Sets are equal:", [...set1], [...set2]);
    } else {
        console.log("BSBSBSBS Sets are not equal:", [...set1], [...set2]);
    }

    return result;
}

export const UserProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    console.log("UserProvider rendering");

    const navigate = useNavigate();
    const navigateLogon = useCallback(() => navigate("/login"), [navigate]);

    const { showSnackbar } = useSnackbar();

    // const [loading, setLoading] = useState<boolean>(true);

    const [userName, setUserName] = useState<string>("");
    const [businessName, setBusinessName] = useState<string>("");
    const [userDisplayName, setUserDisplayNameState] = useState<string>("");
    const [businessDisplayName, setBusinessDisplayNameState] =
        useState<string>("");
    const [businessInfoChatId, setBusinessInfoChatIdState] =
        useState<string | null>(null);
    const [businessSetupCompletedSteps, setBusinessSetupCompletedStepsState] =
        useState<Set<number>>(new Set<number>());

    console.log("UserProvider userDetails:", {
        userName,
        businessName,
        userDisplayName,
        businessDisplayName,
        businessInfoChatId,
    });

    const patchBusiness = async (
        update: Partial<{ displayName: string; infoChatId: string }>,
    ) => {
        console.log("patchBusiness", update);
        await getAPIClient().patch("/api/v1/business", update);
    };

    const patchUser = async (update: Partial<{ displayName: string }>) => {
        console.log("patchUser", update);
        await getAPIClient().patch("/api/v1/user", update);
    };

    const setUserDisplayName = useCallback(
        async (newUserDisplayName: string) => {
            if (newUserDisplayName === userDisplayName) {
                return;
            }

            console.log("setUserDisplayName", newUserDisplayName);
            try {
                await patchUser({ displayName: newUserDisplayName });
                setUserDisplayNameState(newUserDisplayName);
            } catch (error) {
                showSnackbar("Failed to update user display name", "error");
            }
        },
        [userDisplayName, showSnackbar],
    );

    const setBusinessDisplayName = useCallback(
        async (newBusinessDisplayName: string) => {
            if (newBusinessDisplayName === businessDisplayName) {
                return;
            }

            console.log("setBusinessDisplayName", newBusinessDisplayName);
            try {
                await patchBusiness({ displayName: newBusinessDisplayName });
                setBusinessDisplayNameState(newBusinessDisplayName);
            } catch (error) {
                showSnackbar("Failed to update business display name", "error");
            }
        },
        [businessDisplayName, showSnackbar],
    );

    const setBusinessInfoChatId = async (newBusinessInfoChatId: string) => {
        if (newBusinessInfoChatId === businessInfoChatId) {
            return;
        }

        console.log("setBusinessInfoChatId", newBusinessInfoChatId);
        try {
            await patchBusiness({ infoChatId: newBusinessInfoChatId });
            setBusinessDisplayNameState(newBusinessInfoChatId);
        } catch (error) {
            showSnackbar("Failed to update business info chat ID", "error");
        }
    };

    const setBusinessSetupCompletedSteps = async (
        newCompletedSteps: Set<number>,
    ) => {
        if (areSetsEqual(newCompletedSteps, businessSetupCompletedSteps)) {
            return;
        }

        console.log("setBusinessSetupCompletedSteps", [...newCompletedSteps]);
        try {
            await getAPIClient().patch("/api/v1/business", {
                setupCompletedSteps: [...newCompletedSteps],
            });
            setBusinessSetupCompletedStepsState(newCompletedSteps);
        } catch (error) {
            showSnackbar("Failed to update business setup completed steps", "error");
        }
    };

    const setBusinessSetupCompletedStep = async (step: number, completed: boolean) => {
        const newBusinessSetupCompletedSteps = new Set<number>(
            businessSetupCompletedSteps,
        );

        if (completed) {
            console.log("Marking business setup step completed: ", step);
            newBusinessSetupCompletedSteps.add(step);
        } else {
            console.log("Marking business setup step incomplete: ", step);
            newBusinessSetupCompletedSteps.delete(step);
        }

        await setBusinessSetupCompletedSteps(newBusinessSetupCompletedSteps);
    }

    useEffect(() => {
        console.log("UserProvider useEffect will fetch user details...");

        const fetchUserAndBusinessDetails = async () => {

            try {
                console.log("will GET /api/v1/user");

                const user = (await getAPIClient().get<User>("/api/v1/user")).data;

                console.log("GET /api/v1/user user data:", user);

                const business = (await getAPIClient().get<Business>("/api/v1/business")).data;

                console.log(
                    `'${user.userName}' updates '${userName}' = ${user.userName != userName}`,
                );                
                console.log(
                    `'${business.businessName}' updates '${businessName}' = ${business.businessName != businessName}`,
                );
                console.log(
                    `'${user.displayName}' updates '${userDisplayName}' = ${user.displayName != userDisplayName}`,
                );
                console.log(
                    `'${business.displayName}' updates '${businessDisplayName}' = ${business.displayName != businessDisplayName}`,
                );
                console.log(
                    `'${business.infoChatId}' updates '${businessInfoChatId}' = ${business.infoChatId != businessInfoChatId}`,
                );

                if (user.userName != userName) {
                    setUserName(user.userName);
                }

                if (business.businessName != businessName) {
                    setBusinessName(business.businessName);
                }

                if (user.displayName != userDisplayName) {
                    setUserDisplayNameState(user.displayName);
                }

                if (business.displayName != businessDisplayName) {
                    setBusinessDisplayNameState(
                        business.displayName,
                    );
                }

                if (business.infoChatId != businessInfoChatId) {
                    setBusinessInfoChatIdState(
                        business.infoChatId ?? null,
                    );
                }

                const newBusinessSetupCompletedSteps = new Set<number>(
                    business.setupCompletedSteps,
                );

                if (
                    !areSetsEqual(
                        newBusinessSetupCompletedSteps,
                        businessSetupCompletedSteps,
                    )
                ) {
                    console.log(`BSBSBSBS Setting business setup completed steps: ${[...newBusinessSetupCompletedSteps]}`);
                    setBusinessSetupCompletedStepsState(
                        newBusinessSetupCompletedSteps,
                    );
                } else {
                    console.log(`BSBSBSBS Business setup completed steps are the same: ${[...newBusinessSetupCompletedSteps]}`);
                }

                console.log("User and business details updated");
            } catch (err) {
                const error = err as AxiosError;
                if (error.response?.status === 401) {
                    showSnackbar("Not authenticated", "error");

                    navigateLogon();
                } else {
                    showSnackbar("Failed to fetch user and business details", "error");
                }
            } finally {
                console.log("Setting loading to false");

                // setLoading(false);
            }
        };

        fetchUserAndBusinessDetails();
    }, [navigateLogon, showSnackbar]);

    const memoizedValue = useMemo(
        () => ({
            userName,
            businessName,
            userDisplayName,
            businessDisplayName,
            businessInfoChatId,
            businessSetupCompletedSteps,
            setUserDisplayName,
            setBusinessDisplayName,
            setBusinessInfoChatId,
            setBusinessSetupCompletedSteps,
            setBusinessSetupCompletedStep,
        }),
        [
            userName,
            businessName,
            userDisplayName,
            businessDisplayName,
            businessInfoChatId,
            businessSetupCompletedSteps,
            setUserDisplayName,
            setBusinessDisplayName,
            setBusinessInfoChatId,
            setBusinessSetupCompletedSteps,
            setBusinessSetupCompletedStep,
        ],
    );

    return !userName ? (
        <CircularProgress />
    ) : (
        <UserContext.Provider value={memoizedValue}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = (): UserContextProps => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};
