// BusinessInfo.tsx

import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Button, Typography } from "@mui/material";
import Chatbot from "./chatbot";
import { useUser } from "./user-provider";
import BusinessSetupStepper from "./business-setup-stepper";

export const BusinessInfo: React.FC = () => {
    const {
        userDisplayName,
        businessDisplayName,
        businessInfoChatId,

        setBusinessInfoChatId,
        setBusinessSetupCompletedStep,
    } = useUser();

    const [isChatFinished, setIsChatFinished] = useState<boolean>(false);

    const [chatId, setChatId] = useState<string | null>(
        businessInfoChatId || null,
    );

    // Callback to handle chatId when chat starts
    const handleChatStarted = async (newChatId: string) => {
        setChatId(newChatId);

        await setBusinessInfoChatId(newChatId);
    };

    const handleChatFinished = async (isFinished: boolean) => {
        setIsChatFinished(isFinished);

        await setBusinessSetupCompletedStep(0, isFinished);
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Welcome, {userDisplayName} from {businessDisplayName}
            </Typography>

            <BusinessSetupStepper activeStep={0} />

            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Provide detailed information about your business to help us
                tailor our services to your needs.
            </Typography>

            {/* <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Why This Step Is Important
            </Typography> */}
            <Typography variant="body1" paragraph>
                The information you provide here will help our AI agents gain a better understanding of your business, its goals, and its unique challenges. By answering the chatbot's questions, you will enable us to tailor our marketing recommendations specifically to your needs.
            </Typography>
            <Typography variant="body1" paragraph>
                Your responses will be combined with any other data or integrations you provide to create personalized marketing strategies, helping you reach your target audience effectively and grow your business.
            </Typography>
            <Typography variant="body1" paragraph>
                Take your time to provide as much detail as you can. The more we know about your business, the more we can help you achieve your goals. Don't worry if you're unsure about something—just answer to the best of your ability!
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Chatbot
                        persona="businessInfo"
                        userMemoriesName="Business Memories"
                        chatId={chatId}
                        onChatStarted={handleChatStarted}
                        onFinished={handleChatFinished}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default BusinessInfo;
