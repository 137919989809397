import React from "react";
import { useUser } from "./user-provider";
import { 
    Stepper, 
    Step, 
    StepLabel, 
    Button, 
    MobileStepper, 
    useMediaQuery, 
    useTheme, 
    Paper,
    Typography, 
    Box,
    Divider
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const steps = [
    { 
        buttonText: "Business Info",
        label: "Tell us about your business", 
        path: "/business-info" 
    },
    {
        buttonText: "Business Integrations",
        label: "Integrate your business data sources",
        path: "/business-integrations",
    },
    { 
        buttonText: "Business Data Import",
        label: "Import other business data", 
        path: "/business-data-import" 
    },
    { 
        buttonText: "Create a Project",
        label: "Create your first project", 
        path: "/projects" 
    },
];

type BusinessSetupStepperProps = {
    activeStep?: number;
};

const BusinessSetupStepper: React.FC<BusinessSetupStepperProps> = ({
    activeStep,
}) => {
    const navigate = useNavigate();
    const { businessSetupCompletedSteps: completedSteps } = useUser();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    console.log(`completed steps: ${[...completedSteps]}`);

    const handleStepClick = (path: string) => {
        navigate(path);
    };

    const MobileStepperVariant = () => (
        <Paper elevation={0} square>
            <MobileStepper
                variant="text"
                steps={steps.length}
                position="static"
                activeStep={activeStep ?? -1}
                nextButton={
                    <Button
                        size="small"
                        onClick={() => activeStep !== undefined && handleStepClick(steps[activeStep + 1]?.path)}
                        disabled={activeStep === undefined || activeStep === steps.length - 1}
                    >
                        Next
                        <KeyboardArrowRight />
                    </Button>
                }
                backButton={
                    <Button
                        size="small"
                        onClick={() => activeStep !== undefined && handleStepClick(steps[activeStep - 1]?.path)}
                        disabled={activeStep === undefined || activeStep === 0}
                    >
                        <KeyboardArrowLeft />
                        Back
                    </Button>
                }
            />
            <div style={{ padding: '16px', textAlign: 'center' }}>
                {activeStep !== undefined && steps[activeStep]?.label}
            </div>
        </Paper>
    );

    const StepperContent = React.useMemo(() => {
        console.log('BSBSBSBS steps:', steps, 'activeStep:', activeStep, 'completedSteps:', [...completedSteps]);
        return (
            <Stepper 
                activeStep={activeStep ?? -1} 
                nonLinear 
                orientation={isTablet ? 'vertical' : 'horizontal'}
                alternativeLabel={!isTablet}
                style={{ width: '100%' }}
            >
                {steps.map((step, index) => (
                    <Step key={step.label} completed={completedSteps.has(index)}>
                        <StepLabel>
                            <div style={{ 
                                display: 'flex', 
                                flexDirection: isTablet ? 'row' : 'column',
                                alignItems: 'center', 
                                gap: '8px'
                            }}>
                                <Button
                                    variant={index === activeStep ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => handleStepClick(step.path)}
                                >
                                    {step.buttonText}
                                </Button>
                                <div style={{ 
                                    textAlign: isTablet ? 'left' : 'center', 
                                    fontSize: '0.875rem',
                                    marginLeft: isTablet ? '16px' : '0'
                                }}>
                                    {step.label}
                                </div>
                            </div>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        );
    }, [activeStep, isTablet, steps, completedSteps, handleStepClick]);

    return (
        <Box
            sx={{
                borderRadius: 1, // MUI Accordion default border radius
                boxShadow: 1,    // MUI Accordion default shadow
                mt: 2,           // Vertical spacing similar to Accordion
                mb: 2,           // Vertical spacing similar to Accordion
                backgroundColor: '#fafafa',
                border: '1px solid #e0e0e0',
                padding: 2,
            }}
        >
            <Box
                sx={{
                    minHeight: 'fit-content',
                    display: 'flex',
                    alignItems: 'flex-start', // Changed from 'center'
                    flexDirection: 'column',
                }}
            >
                <Box sx={{ mt: 6 }} />
  
                {isMobile ? <MobileStepperVariant /> : StepperContent}
            </Box>
        </Box>
    );
};

export default BusinessSetupStepper;
