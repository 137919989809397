// UserSettings.tsx

import React, { useState } from "react";
import {
    Container,
    Typography,
    TextField,
    Button,
    Box,
    CircularProgress,
    Snackbar,
    Alert,
} from "@mui/material";

import { useUser } from "./user-provider";

export const UserSettings: React.FC = () => {
    const {
        userName,
        userDisplayName,
        businessName,
        businessDisplayName,
        setUserDisplayName,
        setBusinessDisplayName,
    } = useUser();

    const [saving, setSaving] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");

    const handleChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { name, value } = e.target;
        if (name === "userDisplayName") {
            await setUserDisplayName(value);
        } else if (name === "businessDisplayName") {
            await setBusinessDisplayName(value);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setSaving(true);
        setError("");
        setSuccess("");

        // Basic validation
        if (
            userDisplayName.trim() === "" ||
            businessDisplayName.trim() === ""
        ) {
            setError("Display names cannot be empty.");
            setSaving(false);
            return;
        }

        try {
            // The update functions already handle API calls
            setSuccess("User settings updated successfully.");
        } catch (err) {
            setError("Failed to update user settings.");
        } finally {
            setSaving(false);
        }
    };

    const handleCloseSnackbar = (
        event?: React.SyntheticEvent | Event,
        reason?: string,
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setError("");
        setSuccess("");
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>
                User Settings
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 2 }}
            >
                {/* Displayed User Name (Non-editable) */}
                <TextField
                    label="User Name"
                    name="userName"
                    value={userName}
                    fullWidth
                    margin="normal"
                    disabled
                />
                {/* Displayed Business Name (Non-editable) */}
                <TextField
                    label="Business Name"
                    name="businessName"
                    value={businessName}
                    fullWidth
                    margin="normal"
                    disabled
                />
                {/* Editable User Display Name */}
                <TextField
                    label="User Display Name"
                    name="userDisplayName"
                    value={userDisplayName}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                />
                {/* Editable Business Display Name */}
                <TextField
                    label="Business Display Name"
                    name="businessDisplayName"
                    value={businessDisplayName}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                />
                {/* Error Alert */}
                {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {error}
                    </Alert>
                )}
                {/* Save Button */}
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={saving}
                    sx={{ mt: 3 }}
                >
                    {saving ? <CircularProgress size={24} /> : "Save Changes"}
                </Button>
            </Box>

            {/* Success Snackbar */}
            <Snackbar
                open={!!success}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    {success}
                </Alert>
            </Snackbar>

            {/* Error Snackbar */}
            <Snackbar
                open={!!error && !success}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {error}
                </Alert>
            </Snackbar>
        </Container>
    );
};

