// sse-context.tsx

import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useCallback,
    useMemo,
} from 'react';
import { getClientId } from './client-id';
import { useIsAuthenticated } from './auth-context';
import { useState } from 'react';

type SSEMessage = {
    event: string;
    update: any; // Replace 'any' with the actual type of your update data
}; // Updated SSEMessage type

// Define bare context types
type SSESubscribeContextType = (
    callback: (message: SSEMessage) => Promise<void> | void
) => () => void;

type SSEConnectionContextType = boolean;

// Create separate contexts with bare types
const SSESubscribeContext = createContext<SSESubscribeContextType | undefined>(undefined);
const SSEConnectionContext = createContext<SSEConnectionContextType | undefined>(undefined);

export const SSEProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    console.log('SSEProvider rendering');

    const isAuthenticated = useIsAuthenticated();
    const clientId = getClientId();

    const listenersRef = useRef<Set<(message: SSEMessage) => void>>(new Set());

    // Reconnection settings
    const eventSourceRef = useRef<EventSource | null>(null);
    const isUnmountingRef = useRef<boolean>(false);
    const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const [isConnected, setIsConnected] = useState<boolean>(false); // Bare isConnected state

    const lastEventReceivedRef = useRef<number>(Date.now());

    const connect = useCallback(() => {
        if (isUnmountingRef.current) {
            console.log('Component is unmounting, aborting SSE connection');
            return;
        }

        if (!isAuthenticated) {
            console.log('User is not authenticated, aborting SSE connection');
            return;
        }

        console.log('Attempting to connect to SSE');

        const eventSource = new EventSource(`/api/v1/sse/${clientId}`);
        eventSourceRef.current = eventSource;

        console.log('SSEProvider eventSource:', eventSource);

        eventSource.onopen = () => {
            setIsConnected(true);
        };

        eventSource.onmessage = (event) => {
            console.log('SSE message:', event.data);
            lastEventReceivedRef.current = Date.now(); // Update the timestamp on each message

            let message: SSEMessage;

            try {
                message = JSON.parse(event.data);
            } catch (error) {
                console.error('Failed to parse SSE message:', error);
                return;
            }

            if (message.event === 'keep-alive') {
                console.log(`Keep-alive sent at ${message.update.timestamp}`);
            } else if (message.event === 'start-up') { 
                console.log(`Start-up event sent at ${message.update.timestamp}`);
                return;
            }

            console.log(
                `sending to ${listenersRef.current.size} listeners:`,
                message
            );

            listenersRef.current.forEach((listener) => {
                try {
                    const result = listener(message) as (undefined | Promise<void>);
                    if (result && result.catch) {
                        result.catch((error) => console.error('Listener error:', error));
                    }
                } catch (error) {
                    console.error('Listener error:', error);
                }
            });
        };

        eventSource.onerror = (error) => {
            console.error('SSE error:', error);

            setIsConnected(false); // Update connection status

            // Close the current connection
            eventSource.close();

            if (isUnmountingRef.current) {
                return;
            }

            // Clear any existing timeout
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
                reconnectTimeoutRef.current = null;
            }

            // Attempt to reconnect after a fixed delay
            const reconnectDelay = 1000; // 1 second
            console.log(`Reconnecting in ${reconnectDelay / 1000} seconds...`);

            reconnectTimeoutRef.current = setTimeout(() => {
                connect();
            }, reconnectDelay);
        };
    }, [isAuthenticated, clientId]);

    useEffect(() => {
        console.log('SSEProvider mounted or authentication status changed');
        isUnmountingRef.current = false;

        if (isAuthenticated) {
            connect();
        } else {
            console.log('User is not authenticated, closing SSE connection if any');

            // Close any existing SSE connections
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
                eventSourceRef.current = null;
            }
        }

        return () => {
            console.log('SSEProvider unmounting or authentication status changed');
            isUnmountingRef.current = true;

            // Close SSE connection
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
                eventSourceRef.current = null;
            }

            // Clear any pending reconnect timeout
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
                reconnectTimeoutRef.current = null;
            }
        };
    }, [isAuthenticated, connect]);

    // Effect to monitor event reception and reconnect if necessary
    useEffect(() => {
        if (!isConnected) return;

        const interval = setInterval(() => {
            const now = Date.now();
            const timeSinceLastEvent = now - lastEventReceivedRef.current;

            if (timeSinceLastEvent > 12000) { // 12 seconds
                console.log('No events received in the last 12 seconds. Reconnecting...');
                if (eventSourceRef.current) {
                    eventSourceRef.current.close();
                    eventSourceRef.current = null;
                }
                connect();
            }
        }, 5000); // Check every 5 seconds

        return () => {
            clearInterval(interval);
        };
    }, [isConnected, connect]);

    const subscribe = useCallback(
        (callback: (message: SSEMessage) => void) => {
            console.log('New SSE subscriber added');

            listenersRef.current.add(callback);
            return () => {
                console.log('SSE subscriber removed');

                listenersRef.current.delete(callback);
            };
        },
        []
    );

    return (
        <SSESubscribeContext.Provider value={subscribe}>
            <SSEConnectionContext.Provider value={isConnected}>
                {children}
            </SSEConnectionContext.Provider>
        </SSESubscribeContext.Provider>
    );
};

// Update hooks to return bare values
export const useSSE = () => {
    const subscribe = useContext(SSESubscribeContext);
    if (!subscribe) {
        throw new Error('useSSE must be used within an SSEProvider');
    }
    return subscribe;
};

export const useSSEIsConnected = () => {
    const isConnected = useContext(SSEConnectionContext);
    if (isConnected === undefined) {
        throw new Error('useSSEIsConnected must be used within an SSEProvider');
    }
    return isConnected;
};
